<template>
  <aside class="main-sidebar flex fd-c jc-sb fgap2">

    <div class="flex fd-c fgap2">
      <div class="text-center p-1">
        <router-link :to="{ name: 'Home' }">
          <img src="@/assets/img/logo.png" alt="SOWport">
        </router-link>
      </div>

      <ul class="navigation">
        <p class="text-center text-light text-sm">Menu</p>
        <li>
          <router-link :to="{ name: 'Home' }">
            <span class="material-symbols-outlined" style="font-size: 3rem;">
              dashboard
            </span>
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Dashboard</span>
          </div>
        </li>
        <li>
          <router-link :to="{ name: 'Processes' }">
            <span class="material-symbols-outlined" style="font-size: 3rem;">
              conveyor_belt
            </span>
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Processos</span>
          </div>
        </li>
        <li>
          <router-link :to="{ name: 'ProcessesReport' }">
            <span class="material-symbols-outlined" style="font-size: 3rem;">
              lab_profile
            </span>
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Relatórios</span>
          </div>
        </li>
        <!--        <li>-->
        <!--          <router-link :to="{ name: 'FinanceiroPrevisaoNumerario' }">-->
        <!--            <span class="material-symbols-outlined" style="font-size: 3rem;">-->
        <!--              Event_Upcoming-->
        <!--            </span>-->
        <!--          </router-link>-->
        <!--          <div class="nav-tooltip">-->
        <!--            <span class="text-medium">Previsão Numerário</span>-->
        <!--          </div>-->
        <!--        </li>-->
      </ul>
    </div>

    <div class="flex ai-c jc-c text-center mb-1">
      <a href="#" @click.prevent="logout">
        <span class="material-symbols-outlined" style="font-size: 3rem;">
          logout
        </span>
      </a>
    </div>

  </aside>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Sidebar',
  methods: {
    ...mapActions('auth', ['logout']),
  },
};
</script>

<style scoped>
.main-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80px;
  background-color: var(--white-color);
  z-index: 999999;
}

.main-sidebar .navigation a {
  display: block;
  text-align: center;
  padding: .75rem 1rem;
  opacity: .6;
  position: relative;
  transition: var(--all-transition);
}

.main-sidebar .navigation a:hover {
  color: var(--primary-color);
}

.main-sidebar .navigation a::before {
  content: '';
  display: block;
  position: absolute;
  top: .75rem;
  bottom: .75rem;
  left: 0;
  width: 2px;
  background-color: var(--primary-color);
  transition: var(--all-transition);
  opacity: 0;
}

.main-sidebar .navigation a.router-link-exact-active,
.main-sidebar .navigation a.router-link-exact-active::before,
.main-sidebar .navigation a:hover {
  opacity: 1;
}

.navigation li {
  position: relative;
}

.navigation li .nav-tooltip {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate3d(0, -50%, 0);
  background-color: white;
  z-index: 99;
  pointer-events: none;
  padding: 0.75rem;
  border-radius: var(--medium-border-radius);
  color: var(--primary-color);
  box-shadow: var(--box-shadow);
  border: 1px solid var(--primary-color);
  transition: var(--all-transition);
}

.navigation li .nav-tooltip::after,
.navigation li .nav-tooltip::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  border-width: 5px 10px 5px 0;
  border-color: transparent white transparent transparent;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
}

.navigation li .nav-tooltip::before {
  border-width: 6px 11px 6px 0;
  left: -11px;
  border-color: transparent var(--primary-color) transparent transparent;
}

.navigation li:hover .nav-tooltip {
  left: calc(100% + 10px);
  opacity: 1;
  visibility: visible;
}
</style>
