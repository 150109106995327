<template>
  <div class="login-page full-height columns">
    <div class="span-6 full-height bg p-2 hide-mobile">
      <router-link :to="{ name: 'Login' }">
        <img alt="SOWport" src="@/assets/img/logo-completa.png">
      </router-link>
    </div>
    <div class="span-6 flex fd-c jc-c ai-c">
      <router-link :to="{ name: 'Login' }" class="hide-desktop">
        <img alt="SOWport" src="@/assets/img/logo-completa.png">
      </router-link>
      <div style="width: 100%;" class="medium-border-radius pv-4 ph-6 text-center flex fd-c jc-c">
        <h1 class="mb-1 text-xg text-bold">Portal SOWport</h1>
        <p class="mb-3">Digite o seu email e senha para acessar!</p>

        <form class="flex fd-c fgap2" @submit.prevent="login">
          <Input
            v-model="userData.email"
            :required="true"
            className="input-login"
            label="Email"
            name="email"
            placeholder="Seu email"
            type="email"
          />

          <Input
            v-model="userData.senha"
            :error="error"
            :required="true"
            className="input-login"
            label="Senha"
            name="senha"
            placeholder="Sua senha"
            type="password"
          />

          <div class="pv-1 text-right">
            <router-link :to="{name: 'ForgotPassword'}">Esqueceu sua senha?</router-link>
          </div>

          <button class="btn solid primary full-width extra-big" type="submit">ACESSAR</button>
          <div v-if="loading">carregando</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
import Input from '@/components/DataInput/Input.vue';

export default {
  name: 'Login',
  components: {
    Input,
  },
  computed: {
    ...mapState(['loading']),
  },
  data() {
    return {
      userData: {
        email: '',
        senha: '',
      },
      error: new Error(),
    };
  },
  methods: {
    ...mapActions(['toggleLoading']),
    ...mapActions('auth', ['updateUser']),
    async login() {
      this.toggleLoading(true);
      this.error = new Error();
      try {
        const params = new URLSearchParams();
        params.append('grant_type', 'password');
        params.append('username', this.userData.email);
        params.append('password', this.userData.senha);

        const response = await api.post('/oauth/token', params, {
          auth: {
            username: process.env.VUE_APP_API_USER,
            password: process.env.VUE_APP_API_PWD,
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (response) {
          const userResponse = await api.get('/credential');
          this.updateUser(userResponse.data.data);
          this.$router.push('/');
        }
      } catch (err) {
        if (err.message === 'Bad credentials') {
          this.error = new Error('Usuário ou senha inválido.');
        } else {
          this.$toast.error('Houve algum problema ao tentar realizar o login. Caso o problema continue, entre em contato conosco.');
        }
      }
      this.toggleLoading(false);
    },
  },
};
</script>

<style scoped>
.login-page .bg {
  background: url('/bg-login-new.jpg') center center / cover no-repeat;
}

form {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.login-page.columns {
  background: var(--white-color);
}
</style>
