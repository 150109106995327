<template>
  <transition name="modalProcess" mode="out-in">
    <div v-if="handler" class="modal-process">
      <div class="wrapper">
        <div class="modal-header flex ai-c jc-sb fw-w">
          <div class="flex ai-c fgap2">
            <!-- <h2 class="text-primary text-xg"><strong>PRI</strong>{{ data.identification }}</h2> -->
            <h2 class="text-primary text-xg">{{ getNewReference() }}</h2>
            <div class="tag-status" :style="`background-color: ${data.step.color ? data.step.color : 'white'}; color: ${data.step.textColor};`">
              {{ data.step.alias }}
            </div>
            <p class="text-light">STATUS: <span class="text-bg text-bold">"{{ data.status }}"</span></p>
          </div>

          <div @click.prevent="$emit('request-close', true)" class="close-modal">X</div>
        </div>

        <div class="modal-summary flex fw-w ai-c jc-sb fgap2 pv-2">
          <div>
            <div class="flex ai-fs mb-1">
              <BuildingIcon class="text-light mr-1" />
              <div>
                <p class="text-light text-nm">Cliente</p>
                <p class="text-bold text-primary" v-if="data.customer">{{data.customer.name}}</p>
                <p class="text-thin" v-else>-</p>
              </div>
            </div>
          </div>

          <div>
            <div class="flex ai-fs mb-1">
              <BoxesIcon class="text-light mr-1" />
              <div>
                <p class="text-light text-nm">Exportador</p>
                <p class="text-bold text-primary" v-if="data.exporter">{{data.exporter.name}}</p>
                <p class="text-thin" v-else>-</p>
              </div>
            </div>
          </div>

          <div>
            <div class="flex ai-fs mb-1">
              <BoxesIcon class="text-light mr-1" />
              <div>
                <p class="text-light text-nm">Fabricante</p>
                <p class="text-bold text-primary" v-if="data.manufacturer">{{data.manufacturer.name}}</p>
                <p class="text-thin" v-else>-</p>
              </div>
            </div>
          </div>

          <div>
            <div class="flex ai-fs mb-1">
              <FileIcon class="text-light mr-1" />
              <div>
                <p class="text-light text-nm">Ref Pedido / Cliente</p>
                <p class="text-bold text-primary" v-if="data.customerRef">{{data.customerRef}}</p>
                <p class="text-thin" v-else>-</p>
              </div>
            </div>
          </div>

          <div>
            <div class="flex ai-fs mb-1">
              <InvoiceIcon class="text-light mr-1" />
              <div>
                <p class="text-light">Invoice</p>
                <p class="text-bold text-primary" v-if="data.invoice">{{data.invoice}}</p>
                <p class="text-thin" v-else>-</p>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-tabs">
          <ul class="tabs flex ai-c fgap2">
            <li
              :class="['flex ai-c jc-c', activeTab == 1 ? 'active' : '']"
              @click="activeTab = 1"
            >
              <span>Dados Gerais</span>
            </li>
            <li
              :class="['flex ai-c jc-c', activeTab == 4 ? 'active' : '']"
              @click="activeTab = 4"
            >
              <span>Documentos</span>
              <span class="tag documentos ml-1">{{data.documents ? data.documents.length : 0}}</span>
            </li>
            <li
              :class="['flex ai-c jc-c', activeTab == 5 ? 'active' : '']"
              @click="activeTab = 5"
            >
              <span>Histórico</span>
              <span class="tag importacao ml-1">{{ data.history ? data.history.length : 0}}</span>
            </li>
          </ul>
          <div class="tab-content">
            <div v-if="activeTab == 1">
              <div class="columns gap2">
                <div class="span-2 flex fd-c fgap2">
                  <h3 class="text-primary text-md text-bold">LINHA DO TEMPO</h3>
                  <UpdatesList :data="updates" />
                </div>

                <div class="span-7 flex fd-c fgap2">
                  <h3 class="text-primary text-md text-bold">INFORMAÇÕES GERAIS</h3>
                  <div class="columns gap2">
                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Invoice</p>
                      <p class="text-bold text-nm" v-if="data.invoice">{{data.invoice}}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Data Invoice</p>
                      <p class="text-bold" v-if="data.invoiceDate">{{data.invoiceDate | moment("DD/MM/YYYY")}}</p>
                     <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Valor CIF</p>
                      <p class="text-bold" v-if="data.cifValue">{{data.cifValue}}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Ref Pedido</p>
                      <p class="text-bold" v-if="data.customerRef">{{data.customerRef}}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Outras Ref</p>
                      <p class="text-bold" v-if="data.anotherRef">{{data.anotherRef}}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Local de Embarque</p>
                      <p class="text-bold" v-if="data.placeOfLoading">{{data.placeOfLoading}}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Despachante</p>
                      <p class="text-bold" v-if="data.customBroker">{{data.customBroker.name}}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Ref Despachante</p>
                      <p class="text-bold" v-if="data.customBrokerRef">{{data.customBrokerRef}}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>
                  </div>

                  <div class="offwhite-bg p-2 medium-border-radius flex fd-c fgap2">
                    <h3 class="text-primary text-medium flex ai-fe fgap2">
                      <span class="mb-1">INFORMAÇÕES LOGÍSTICA</span>
                      <AirFreightIcon size="50px" />
                    </h3>

                    <div class="columns gap2">
                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Incoterm</p>
                        <p class="text-bold text-primary" v-if="data.incoterm">{{data.incoterm}}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Via de Transporte</p>
                        <p class="text-bold text-primary" v-if="data.wayOfTransport">
                          {{data.wayOfTransport}}
                        </p>
                        <p class="text-thin" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Tipo de Embarque</p>
                        <p class="text-bold text-primary" v-if="data.typeOfBoarding">
                          {{getTipoEmbarque(data)}}
                        </p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Armador</p>
                        <p class="text-bold text-primary" v-if="data.shipowner">{{data.shipowner.name}}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">BL/AWB/CRT</p>
                        <p class="text-bold text-primary" v-if="data.billOfLading">{{data.billOfLading}}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Veículo / Navio</p>
                        <p class="text-bold text-primary" v-if="data.vehicleTranshipment">{{data.vehicleTranshipment}}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Navio de Embarque</p>
                        <p class="text-bold text-primary" v-if="data.vehicleTranshipment">{{data.vehicleTranshipment}}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Nº CE-Mercante</p>
                        <p class="text-bold text-primary" v-if="data.ceMercante">{{data.ceMercante}}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-6">
                        <p class="text-nm text-light text-normal">Forwarder</p>
                        <p class="text-bold text-primary" v-if="data.freightForwarder">{{data.freightForwarder.name}}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-6">
                        <p class="text-nm text-light text-normal">URF de Entrada</p>
                        <p class="text-bold text-primary" v-if="data.destinationPort">{{data.destinationPort.alias}}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-6">
                        <p class="text-nm text-light text-normal">Recinto Alfandegado</p>
                        <p class="text-bold text-primary" v-if="data.customsEnclosure">{{data.customsEnclosure.alias}}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-4">
                        <p class="text-nm text-light text-normal">Transportadora</p>
                        <p class="text-bold text-primary" v-if="data.conveyor">{{data.conveyor.name}}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-4">
                        <p class="text-nm text-light text-normal">Armazém Geral</p>
                        <p class="text-bold text-primary" v-if="data.generalWarehouse">{{data.generalWarehouse.name}}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>
                    </div>
                  </div>

                  <h3 class="text-primary text-medium">FATURAMENTO</h3>
                  <div class="columns gap2">
                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Armazenagem</p>
                      <p class="text-bold" v-if="data.storagePeriod">{{data.storagePeriod}}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Notas Fiscais</p>
                      <p class="text-bold" v-if="data.billingNFsEmissionDate">{{data.billingNFsEmissionDate | moment("DD/MM/YYYY")}}</p>
                     <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Faturamento</p>
                      <p class="text-bold" v-if="data.billingBillCustomerDate">{{data.billingBillCustomerDate | moment("DD/MM/YYYY")}}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Email</p>
                      <p class="text-bold" v-if="data.billingEmailDate">{{data.billingEmailDate | moment("DD/MM/YYYY")}}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Envio</p>
                      <p class="text-bold" v-if="data.billingSendDate">{{data.billingSendDate | moment("DD/MM/YYYY")}}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Encerramento Desp</p>
                      <p class="text-bold" v-if="data.billingCustomBrokerFinishDate">{{data.billingCustomBrokerFinishDate | moment("DD/MM/YYYY")}}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Empresa</p>
                      <p class="text-bold" v-if="data.billingSendCompanyDescription">{{data.billingSendCompanyDescription}}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Tracking / Rastreio</p>
                      <p class="text-bold" v-if="data.billingSendTrackingDescription">{{data.billingSendTrackingDescription}}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>
                  </div>
                </div>

                <div class="span-3 flex fd-c fgap2">
                  <div class="primary-bg p-2 medium-border-radius">
                    <h3 class="text-accent text-md text-center mb-2">DECLARAÇÃO DE IMPORTAÇÃO</h3>
                    <div class="columns gap2">
                      <div class="span-6">
                        <p class="text-nm text-white text-normal">Registro</p>
                        <p class="text-bold text-white" v-if="data.diRegistryDate">{{data.diRegistryDate | moment("DD/MM/YYYY")}}</p>
                        <p class="text-thin text-white" v-else>-</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-white text-normal">Desembaraço</p>
                        <p class="text-bold text-white" v-if="data.diResourcefulnessDate">{{data.diResourcefulnessDate | moment("DD/MM/YYYY")}}</p>
                        <p class="text-thin text-white" v-else>-</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-white text-normal">Nº Registro</p>
                        <p class="text-bold text-white">{{data.diNumber}}</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-white text-normal">Protocolo</p>
                        <p class="text-bold text-white" v-if="data.diProtocol"> {{data.diProtocol}} </p>
                        <p class="text-bold text-white" v-else>-</p>
                      </div>
                    </div>
                  </div>

                  <div class="flex ai-c jc-sb p-2 medium-border-radius" style="background-color: #F8F8F8;" v-if="data.diNumber">
                    <div>
                      <DoubleCheckIcon size="3rem" :class="data.diChannel === 'VERDE' ? 'text-success' : (data.diChannel === 'AMARELO' ? 'text-warning' : (data.diChannel === 'VERMELHO' ? 'text-danger' : (data.diChannel === 'CINZA' ? '' : '')))" />
                    </div>
                    <div class="flex1 text-center">
                      <h6 class="text-bold text-light">DESEMBARAÇADO</h6>
                      <p :class="data.diChannel === 'VERDE' ? 'text-success' : (data.diChannel === 'AMARELO' ? 'text-warning' : (data.diChannel === 'VERMELHO' ? 'text-danger' : (data.diChannel === 'CINZA' ? '' : '')))">Canal <strong>{{data.diChannel}}</strong></p>
                    </div>
                  </div>

                  <div class="flex fd-c fgap2">
                    <h3 class="text-primary text-medium">MAPA</h3>
                    <div class="columns gap2">
                      <div class="span-6">
                        <p class="text-nm text-normal">Vistoria</p>
                        <p class="text-bold" v-if="data.datesInspectionMAPADate">{{data.datesInspectionMAPADate | moment("DD/MM/YYYY")}}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-normal">Liberação</p>
                        <p class="text-bold" v-if="data.datesReleaseMAPADate">{{data.datesReleaseMAPADate | moment("DD/MM/YYYY")}}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>
                    </div>
                  </div>

                  <div class="flex fd-c fgap2">
                    <h3 class="text-primary text-medium">OUTRAS DATAS</h3>
                    <div class="columns gap2">
                      <div class="span-6">
                        <p class="text-nm text-normal">AWB/BL Liberado</p>
                        <p class="text-bold" v-if="data.datesBillOfLadingReleaseDate">{{data.datesBillOfLadingReleaseDate | moment("DD/MM/YYYY")}}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-normal">Fat. Recinto</p>
                        <p class="text-bold" v-if="data.datesEnclosureBillingDate">{{data.datesEnclosureBillingDate | moment("DD/MM/YYYY")}}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-normal">Liberação Porto</p>
                        <p class="text-bold" v-if="data.datesComexDate">{{data.datesComexDate | moment("DD/MM/YYYY")}}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-normal">Vínculo no Recinto</p>
                        <p class="text-bold" v-if="data.datesLinkInTheEnclosureDate">{{data.datesLinkInTheEnclosureDate | moment("DD/MM/YYYY")}}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="activeTab == 4">
              <div class="flex ai-c jc-sb fgap2">
                <div>
                  <h4 class="text-bold">DOCUMENTOS</h4>
                  <p>Total de {{data.documents.length}} documentos cadastradas</p>
                </div>

                <div>
                  <div class="flex ai-c fgap1 mb-1">
                    <SearchIcon />
                    <p>Pesquisar Documentos</p>
                  </div>
                  <div class="search-docs-container relative">
                    <Input
                      name="search"
                      type="search"
                      class="search-docs-input"
                      v-model="documentsSearch"
                      :addStyle="`height: 35px; padding-right: 120px;`"
                    />
                    <button type="button" class="btn solid primary">PESQUISAR</button>
                  </div>
                </div>
              </div>

              <hr class="mb-2" />

              <div class="columns gap2">
                <div class="span-12">
                  <DocumentsCard
                    type="success"
                    :docs="documentResults"
                    :process="data"
                  />
                </div>
              </div>

            </div>

            <div v-if="activeTab == 5">
              <div class="flex ai-c jc-sb fgap2">
                <div>
                  <h4 class="text-bold">HISTÓRICO</h4>
                  <p>Total de {{ data.history.length }} histórico{{ data.history.length > 1 ? 's' : ''}} cadastradas</p>
                </div>

                <div>
                  <div class="flex ai-c fgap1 mb-1">
                    <SearchIcon />
                    <p>Pesquisar Histórico</p>
                  </div>
                  <div class="search-docs-container relative">
                    <input
                      type="search"
                      name="historySearch"
                      class="search-docs-input"
                      v-model="historySearch"
                      style="height: 35px; padding-right: 120px;"
                    >
                    <button type="button" class="btn solid primary">PESQUISAR</button>
                  </div>
                </div>
              </div>

              <hr class="mb-2" />

              <HistoryItem
                :data="{
                  personName: item.createdBy,
                  date: item.created,
                  title: '',
                  description: item.description,
                }"
                v-for="(item, index) in historyResults"
                :key="index"
              />
            </div>
          </div>
        </div>

      </div>

      <Modal
        :backgroundOpacity="0.95"
        :handler="reminderOpen"
        @request-close="reminderOpen = false"
        :useActions="true"
        :maxWidth="40"
      >
        <template #content>
          <h3 class="text-center text-bold mb-2">Lembrete</h3>
          <p class="text-center">{{data.reminder}}</p>
        </template>

        <template #cancel-button>
          <a href="#" class="btn solid primary" @click.prevent="reminderOpen = false">FECHAR</a>
        </template>
      </Modal>

    </div>
  </transition>
</template>

<script>
import {
  FileIcon,
  BuildingIcon,
  InvoiceIcon,
  BoxesIcon,
  DoubleCheckIcon,
  SearchIcon,
} from '@/components/Icons';

import Input from '@/components/DataInput/Input.vue';
import DocumentsCard from '@/components/DocumentsCard.vue';
import HistoryItem from '@/components/HistoryItem.vue';
import Modal from '@/components/Modal.vue';
import UpdatesList from '@/components/UpdatesList.vue';
import AirFreightIcon from '@/components/Icons/AirFreightIcon.vue';

export default {
  name: 'ModalProcess',
  components: {
    // Icons
    FileIcon,
    BuildingIcon,
    InvoiceIcon,
    BoxesIcon,
    AirFreightIcon,
    DoubleCheckIcon,
    SearchIcon,
    // Components
    Input,
    DocumentsCard,
    HistoryItem,
    Modal,
    UpdatesList,
  },
  data() {
    return {
      toggleTest: false,
      reminderOpen: false,
      activeTab: 1,
      dragging: false,
      file: Object,
      documents: [],
      containerData: {},
      historySearch: '',
      documentsSearch: '',
    };
  },
  methods: {
    dragover() {
      this.dragging = true;
    },
    drop(e) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        this.documents.push(file);
      }
      this.dragging = false;
    },
    getNewReference() {
      let ref = 'PRI';

      if (this.data.typeOfProcess === 'ENCOMENDA') {
        ref = 'E';
      }

      if (this.data.typeOfProcess === 'ASSESSORIA') {
        ref = 'A';
      }

      return ref + this.zeroPad(this.data.identification, 5);
    },
    zeroPad(num, places) {
      const zero = places - num.toString().length + 1;

      return Array(+(zero > 0 && zero)).join('0') + num;
    },
    dragleave() {
      this.dragging = false;
    },
    getTipoEmbarque(data) {
      if (data.typeOfBoarding === 'SEM') {
        if (data.wayOfTransport === 'AEREA') {
          return 'Aéreo';
        }

        if (data.wayOfTransport === 'RODOVIARIA') {
          return 'Rodoviário';
        }

        return '-';
      }

      return data.typeOfBoarding;
    },
  },
  mounted() {
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.$emit('request-close', true);
      }
    });
  },
  computed: {
    updates() {
      const itensTimeline = [
        {
          id: 1,
          titulo: 'Prontidão Prevista',
          descricao: this.data.datesEstimatedGoodsReadinesDate !== null ? this.$moment(this.data.datesEstimatedGoodsReadinesDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesEstimatedGoodsReadinesDate !== null,
        },
        {
          id: 2,
          titulo: 'Prontidão Confirmada',
          descricao: this.data.datesGoodsReadinesDate !== null ? this.$moment(this.data.datesGoodsReadinesDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesGoodsReadinesDate !== null,
        },
        {
          id: 3,
          titulo: 'ETD',
          descricao: this.data.datesETD !== null ? this.$moment(this.data.datesETD).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesETD !== null,
        },
        {
          id: 4,
          titulo: 'ETA',
          descricao: this.data.datesETA !== null ? this.$moment(this.data.datesETA).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesETA !== null,
        },
        {
          id: 5,
          titulo: 'Embarque',
          descricao: this.data.datesDepartureDate !== null ? this.$moment(this.data.datesDepartureDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesDepartureDate !== null,
        },
        {
          id: 6,
          titulo: 'Atracação',
          descricao: this.data.mooringDate !== null ? this.$moment(this.data.mooringDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.mooringDate !== null,
        },
        {
          id: 7,
          titulo: 'Chegada',
          descricao: this.data.datesArrivalDate !== null ? this.$moment(this.data.datesArrivalDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesArrivalDate !== null,
        },
        {
          id: 8,
          titulo: 'Presença',
          descricao: this.data.datesGoodsPresenceDate !== null ? this.$moment(this.data.datesGoodsPresenceDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesGoodsPresenceDate !== null,
        },
        {
          id: 9,
          titulo: 'Registro da DI',
          descricao: this.data.diRegistryDate !== null ? this.$moment(this.data.diRegistryDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.diRegistryDate !== null,
        },
        {
          id: 10,
          titulo: 'Desembaraço',
          descricao: this.data.diResourcefulnessDate !== null ? this.$moment(this.data.diResourcefulnessDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.diResourcefulnessDate !== null,
        },
        {
          id: 11,
          titulo: 'Carregamento',
          descricao: this.data.datesGoodsLoadingDate !== null ? this.$moment(this.data.datesGoodsLoadingDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesGoodsLoadingDate !== null,
        },
        {
          id: 12,
          titulo: 'Entrega no Cliente',
          descricao: this.data.datesCustomerDeliverDate !== null ? this.$moment(this.data.datesCustomerDeliverDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesCustomerDeliverDate !== null,
        },
        {
          id: 13,
          titulo: 'Envio do Faturamento',
          descricao: this.data.billingSendDate !== null ? this.$moment(this.data.billingSendDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.billingSendDate !== null,
        },
      ];

      if (this.data.datesDepartureDate !== null && this.data.datesDepartureDate !== 'undefined') {
        const itemEmbarque = itensTimeline.find((item) => item.id === 5);
        const itemETA = itensTimeline.find((item) => item.id === 4);

        itensTimeline[3] = itemEmbarque;
        itensTimeline[4] = itemETA;
      }

      return itensTimeline;
    },
    historyResults() {
      if (this.historySearch) {
        const result = [];

        for (let i = 0; i < Object.keys(this.data.history).length; i += 1) {
          const item = this.data.history[i];
          let returnThis = false;

          for (let y = 0; y < Object.keys(item).length; y += 1) {
            const key = String(Object.values(item)[y]);
            if (new RegExp(this.historySearch, 'gi').test(key)) {
              returnThis = true;
            }
          }

          if (returnThis) {
            result.push(item);
          }
        }

        return result;
      }
      return this.data.history;
    },
    documentResults() {
      if (this.documentsSearch) {
        const result = [];

        for (let i = 0; i < Object.keys(this.data.documents).length; i += 1) {
          const item = this.data.documents[i];
          let returnThis = false;

          for (let y = 0; y < Object.keys(item).length; y += 1) {
            const key = String(Object.values(item)[y]);
            if (new RegExp(this.documentsSearch, 'gi').test(key)) {
              returnThis = true;
            }
          }

          if (returnThis) {
            result.push(item);
          }
        }

        return result;
      }
      return this.data.documents;
    },
  },
  props: {
    handler: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
  .modal-process {
    position: fixed;
    inset: 0;
    background-color: var(--white-color);
    padding: 1rem;
    z-index: 999999999;
  }

  .modal-process .wrapper {
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    border: var(--input-border);
    border-radius: var(--medium-border-radius);
    padding: 0.5rem;
    overflow: auto;
  }

  .tag-status {
    padding: 0.75rem 1.5rem;
    font-weight: 700;
    border-radius: var(--medium-border-radius);
    color: var(--white-color);
  }

  .tag-obs {
    background-color: var(--white-color);
    padding: 0.5rem;
    border-radius: var(--round-border-radius);
  }

  .tag-obs .count {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: var(--round-border-radius);
    background-color: var(--warning-color);
    color: var(--white-color);
    margin-left: 5px;
  }

  .close-modal {
    padding: 0 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
  }

  .modal-header {
    border-bottom: var(--input-border);
    padding: 0.5rem 0 1rem;
  }

  .alert {
    border-radius: var(--medium-border-radius);
  }

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: var(--round-border-radius);
    color: var(--white-color);
  }

  .tag.logistica {
    background-color: var(--accent-color);
  }

  .tag.importacao {
    background-color: var(--primary-color);
  }

  .tag.documentos {
    background-color: var(--light-text-color);
  }

  .modal-tabs .tabs {
    position: relative;
    margin-bottom: -1px;
    overflow: auto;
  }

  .modal-tabs .tabs li {
    flex: 1 1 auto;
    padding: 0.75rem;
    border-top-left-radius: var(--medium-border-radius);
    border-top-right-radius: var(--medium-border-radius);
    border-left: var(--small-border-width) solid transparent;
    border-right: var(--small-border-width) solid transparent;
    border-top: var(--small-border-width) solid transparent;
    cursor: pointer;
    height: 50px;
  }

  .modal-tabs .tabs li.active {
    background-color: var(--white-color);
    border-left: var(--input-border);
    border-right: var(--input-border);
    border-top: var(--input-border);
  }

  .tab-content {
    background-color: var(--white-color);
    padding: 1rem;
    border: var(--input-border);
    border-bottom-left-radius: var(--medium-border-radius);
    border-bottom-right-radius: var(--medium-border-radius);
  }

  .search-license {
    max-width: 380px;
    position: relative;
  }

  body .search-license input {
    padding-right: 9rem;
    height: 35px;
  }

  .tab-content .search-license button {
    height: 35px;
    position: absolute;
    top: 0;
    right: -0;
    bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    line-height: 30px;
    padding: 0 10px;
  }

  .step-1 {
    background-color: #F65694;
  }
  .step-2 {
    background-color: #3B9B28;
  }
  .step-3 {
    background-color: #D6B200;
  }
  .step-4 {
    background-color: #3594E9;
  }
  .step-5 {
    background-color: #A569BD;
  }
  .step-6 {
    background-color: #D07107;
  }

  .modalProcess-enter-active,
  .modalProcess-leave-active {
    transition: var(--all-transition);
  }

  .modalProcess-enter,
  .modalProcess-leave-to {
    transform: scale(0);
  }

  .modalProcess-enter-to,
  .modalProcess.leave {
    transform: scale(1);
  }

  .tab-content input, .tab-content select {
    font-weight: bold;
    height: 30px;
  }

  .search-docs-input {
    min-width: 400px;
  }

  .search-docs-container button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 35px;
    padding: 0 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .upload-placeholder {
    border: var(--medium-border-width) dashed var(--primary-color);
    border-radius: var(--medium-border-radius);
    padding: 1rem;
  }

  .upload-placeholder.active {
    background-color: rgba(16, 67, 117, 0.7);
  }
</style>
