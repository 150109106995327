<template>
  <vue-good-table
    :styleClass="`vgt-table ${className}`"
    :search-options="{
      enabled: search ? search : false,
      placeholder: 'Pesquisar',
    }"
    :pagination-options="{
      enabled: pagination ? pagination : false,
      perPage: 15,
      perPageDropdown: [10, 20, 30, 40, 50, 100],
      jumpFirstOrLast: false,
      firstLabel: 'Primeira Página',
      lastLabel: 'Última Página',
      nextLabel: 'Próximo',
      prevLabel: 'Anterior',
      rowsPerPageLabel: 'Resultados por paǵina',
      pageLabel: 'Página',
      ofLabel: 'de',
      allLabel: 'Todos',
    }"
    :sort-options="{enabled: true, initialSortBy: {field: 'step', type: 'asc'}}"
    compactMode
    :columns="columns"
    :rows="rows"
    @on-row-click="(params) => $emit('rowClick', params.row)"
    ref="goodTable"
  >
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'identification'" class="">
        <span class="text-center text-bold text-nm">{{props.row.identification}}</span>
      </span>
      <span v-else-if="props.column.field == 'status'">
        <span class="text-center text-bold text-sm">{{props.row.status}}</span>
      </span>
      <span v-else-if="props.column.field == 'step'">
        <span class="text-center text-medium text-tag" :style='"background:" + props.row.step.color + ";color:" + props.row.step.textColor + ";"'>
          {{props.row.step.description}}
        </span>
      </span>
      <span v-else-if="props.column.field == 'incoterm'">
        <span class="text-center flex ai-c jc-c fgap1">
          <AirplaneMiniIcon v-if="props.row.wayOfTransport === 'AEREA'" class="text-primary" />
          <TruckMiniIcon v-if="props.row.wayOfTransport === 'RODOVIARIA'"  class="text-black" />
          <ShipMiniIcon v-if="props.row.wayOfTransport === 'MARITIMA'"  class="text-accent" />
          {{props.row.incoterm}}
        </span>
      </span>
      <span v-else>
        {{props.formattedRow[props.column.field]}}
      </span>
    </template>
    <div slot="emptystate"> Nenhum Processo de Importação foi encontrado de acordo com o filtro atual.</div>
  </vue-good-table>

</template>

<script>
import {
  AirplaneMiniIcon,
  TruckMiniIcon,
  ShipMiniIcon,
} from '@/components/Icons';

export default {
  name: 'GoodTable',
  components: {
    AirplaneMiniIcon,
    TruckMiniIcon,
    ShipMiniIcon,
  },
  mounted() {
    const table = this.$refs.goodTable.$el.querySelector('.vgt-responsive');

    const indicatorRight = document.createElement('div');
    indicatorRight.classList.add('table-indicator-container-right', 'hide', 'pointer');

    indicatorRight.innerHTML = `
      <span class="table-indicator text-white relative">
        <div class="flex fd-c ai-c jc-c" style="height: 100%;">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
          <div class="text-sm timered right">
            <p class="text-bold">CLIQUE AQUI</p>
            <p>PARA VER COLUNAS OCULTAS</p>
          </div>
        </div>
      </span>
    `;

    const indicatorLeft = document.createElement('div');
    indicatorLeft.classList.add('table-indicator-container-left', 'hide', 'pointer');

    indicatorLeft.innerHTML = `
      <span class="table-indicator text-white relative">
        <div class="flex fd-c ai-c jc-c" style="height: 100%;">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
          <div class="text-sm timered left">
            <p class="text-bold">CLIQUE AQUI</p>
            <p>PARA VER COLUNAS OCULTAS</p>
          </div>
        </div>
      </span>
    `;

    table.insertAdjacentElement('beforeend', indicatorRight);
    table.insertAdjacentElement('afterbegin', indicatorLeft);

    indicatorLeft.addEventListener('click', this.scrollLeft);
    indicatorRight.addEventListener('click', this.scrollRight);

    table.addEventListener('scroll', this.scrollHorizontal);
    window.addEventListener('resize', this.verifyScroll);
    this.$refs.goodTable.$refs.table.addEventListener('DOMNodeInserted', this.verifyScroll);

    this.verifyScroll();

    setTimeout(() => {
      const hints = table.querySelectorAll('.timered');
      for (let i = 0; i < hints.length; i += 1) {
        const hint = hints[i];
        hint.remove();
      }
    }, 8000);
  },
  methods: {
    scrollHorizontal() {
      const table = this.$refs.goodTable.$el.querySelector('.vgt-responsive');
      const indicatorRight = document.querySelector('.table-indicator-container-right');
      const indicatorLeft = document.querySelector('.table-indicator-container-left');

      if (table.scrollLeft > 0) {
        if (table.scrollLeft + table.offsetWidth > table.scrollWidth - 60) {
          indicatorRight.classList.add('hide');
        } else {
          indicatorRight.classList.remove('hide');
        }
        indicatorLeft.classList.remove('hide');
      }

      if (table.scrollLeft < 60) {
        indicatorRight.classList.remove('hide');
        indicatorLeft.classList.add('hide');
      }

      indicatorLeft.setAttribute('style', `left: ${table.scrollLeft}px;`);
      indicatorRight.setAttribute('style', `left: ${(table.scrollLeft + table.offsetWidth) - 70}px;`);
    },
    verifyScroll() {
      const table = this.$refs.goodTable.$el.querySelector('.vgt-responsive');
      const indicatorRight = table.querySelector('.table-indicator-container-right');
      const indicatorLeft = document.querySelector('.table-indicator-container-left');

      if (table.scrollWidth > table.clientWidth) {
        indicatorRight.classList.remove('hide');
        if (table.scrollLeft + table.offsetWidth > table.scrollWidth - 60) {
          indicatorRight.classList.add('hide');
        } else {
          indicatorRight.classList.remove('hide');
        }
      } else {
        indicatorRight.classList.add('hide');
        if (!(table.scrollWidth === table.clientWidth)) {
          indicatorLeft.classList.remove('hide');
        }
      }
      indicatorLeft.setAttribute('style', `left: ${table.scrollLeft}px;`);
      indicatorRight.setAttribute('style', `left: ${(table.scrollLeft + table.offsetWidth) - 70}px;`);
    },
    scrollRight() {
      const table = this.$refs.goodTable.$el.querySelector('.vgt-responsive');
      table.scrollLeft += 200;
    },
    scrollLeft() {
      const table = this.$refs.goodTable.$el.querySelector('.vgt-responsive');
      table.scrollLeft -= 200;
    },
  },
  beforeDestroy() {
    const table = this.$refs.goodTable.$el.querySelector('.vgt-responsive');
    const indicatorLeft = table.querySelector('.table-indicator-container-left');
    const indicatorRight = table.querySelector('.table-indicator-container-right');

    table.removeEventListener('scroll', this.scrollHorizontal);
    window.removeEventListener('resize', this.verifyScroll);
    this.$refs.goodTable.$refs.table.removeEventListener('DOMNodeInserted', this.verifyScroll);
    indicatorLeft.removeEventListener('click', this.scrollLeft);
    indicatorRight.removeEventListener('click', this.scrollRight);
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    search: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>

</style>
