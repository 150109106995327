var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-good-table',{ref:"goodTable",attrs:{"styleClass":`vgt-table ${_vm.className}`,"search-options":{
    enabled: _vm.search ? _vm.search : false,
    placeholder: 'Pesquisar',
  },"pagination-options":{
    enabled: _vm.pagination ? _vm.pagination : false,
    perPage: 15,
    perPageDropdown: [10, 20, 30, 40, 50, 100],
    jumpFirstOrLast: false,
    firstLabel: 'Primeira Página',
    lastLabel: 'Última Página',
    nextLabel: 'Próximo',
    prevLabel: 'Anterior',
    rowsPerPageLabel: 'Resultados por paǵina',
    pageLabel: 'Página',
    ofLabel: 'de',
    allLabel: 'Todos',
  },"sort-options":{enabled: true, initialSortBy: {field: 'step', type: 'asc'}},"compactMode":"","columns":_vm.columns,"rows":_vm.rows},on:{"on-row-click":(params) => _vm.$emit('rowClick', params.row)},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'identification')?_c('span',{},[_c('span',{staticClass:"text-center text-bold text-nm"},[_vm._v(_vm._s(props.row.identification))])]):(props.column.field == 'status')?_c('span',[_c('span',{staticClass:"text-center text-bold text-sm"},[_vm._v(_vm._s(props.row.status))])]):(props.column.field == 'step')?_c('span',[_c('span',{staticClass:"text-center text-medium text-tag",style:("background:" + props.row.step.color + ";color:" + props.row.step.textColor + ";")},[_vm._v(" "+_vm._s(props.row.step.description)+" ")])]):(props.column.field == 'incoterm')?_c('span',[_c('span',{staticClass:"text-center flex ai-c jc-c fgap1"},[(props.row.wayOfTransport === 'AEREA')?_c('AirplaneMiniIcon',{staticClass:"text-primary"}):_vm._e(),(props.row.wayOfTransport === 'RODOVIARIA')?_c('TruckMiniIcon',{staticClass:"text-black"}):_vm._e(),(props.row.wayOfTransport === 'MARITIMA')?_c('ShipMiniIcon',{staticClass:"text-accent"}):_vm._e(),_vm._v(" "+_vm._s(props.row.incoterm)+" ")],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Nenhum Processo de Importação foi encontrado de acordo com o filtro atual.")])])
}
var staticRenderFns = []

export { render, staticRenderFns }