import Vue from 'vue';
import VueMoment from 'vue-moment';
import Toast from 'vue-toastification';
import VCalendar from 'v-calendar';
import VueApexCharts from 'vue-apexcharts';
import VueNumber from 'vue-number-animation';
import vSelect from 'vue-select';
import VueGoodTablePlugin from 'vue-good-table';
import JsonExcel from 'vue-json-excel';

import router from './router';
import store from './store';
import App from './App.vue';

import 'vue-toastification/dist/index.css';
import 'vue-select/dist/vue-select.css';
import 'vue-good-table/dist/vue-good-table.css';

Vue.config.productionTip = false;

Vue.use(VueMoment);
Vue.use(VueApexCharts);
Vue.use(VueNumber);
Vue.use(VueGoodTablePlugin);
Vue.component('apexchart', VueApexCharts);
Vue.component('v-select', vSelect);
Vue.component('downloadExcel', JsonExcel);

Vue.use(Toast, {
  timeout: 5000,
  icon: false,
});

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
