import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/Auth';
import process from '@/store/Process';
import customer from '@/store/Customer';
import analytics from '@/store/Analytics';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
  },
  mutations: {
    UPDATE_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    toggleLoading(context, payload) {
      context.commit('UPDATE_LOADING', payload);
    },
  },
  modules: {
    auth,
    process,
    customer,
    analytics,
  },
});
