/* eslint-disable */
const columns = {
  8: [{
    label: 'Referência',
    field: 'identification',
    sortable: true,
    width: '50px',
  },
  {
    label: 'Etapa',
    field: 'step',
    sortFn: (x, y, col, rowX, rowY) => {
      return (x.position < y.position ? -1 : (x.position > y.position ? 1 : 0));
    },
  },
  {
    label: 'Empresa',
    field: 'customer.name',
    sortable: true,
    width: '200px',
  },
  {
    label: 'Exportador',
    field: 'exporter.name',
    width: '200px',
  },
  {
    label: 'Ref Pedido',
    field: 'customerRef',
  },
  {
    label: 'Outras Refs',
    field: 'anotherRef',
  },
  {
    label: 'Status',
    field: 'status',
    width: '200px',
  },
  {
    label: 'Invoice',
    field: 'invoice',
  },
  {
    label: 'Incoterm',
    field: 'incoterm',
    tooltip: 'Incoterm',
  },
  {
    label: 'CNTR',
    field: 'typeOfBoarding',
  },
  {
    label: 'Prontidão Prev',
    field: 'datesEstimatedGoodsReadinesDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
    sortable: true,
  },
  {
    label: 'Forwarder',
    field: 'freightForwarder.name',
  },
  {
    label: 'ETD',
    field: 'datesETD',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
    sortable: true,
  },
  {
    label: 'ETA',
    field: 'datesETA',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
    sortable: true,
  },
  {
    label: 'Valor CIF',
    field: 'cifValue',
    formatFn: moneyFormat
  },
  ],
  // Produção
  7: [{
    label: 'Referência',
    field: 'identification',
    sortable: true,
    width: '50px',
  },
  {
    label: 'Etapa',
    field: 'step',
    sortable: true,
  },
  {
    label: 'Empresa',
    field: 'customer.name',
    sortable: true,
    width: '200px',
  },
  {
    label: 'Exportador',
    field: 'exporter.name',
    width: '200px',
  },
  {
    label: 'Ref Pedido',
    field: 'customerRef',
  },
  {
    label: 'Outras Refs',
    field: 'anotherRef',
  },
  {
    label: 'Status',
    field: 'status',
    width: '200px',
  },
  {
    label: 'Invoice',
    field: 'invoice',
  },
  {
    label: 'Incoterm',
    field: 'incoterm',
  },
  {
    label: 'CNTR',
    field: 'typeOfBoarding',
  },
  {
    label: 'Prontidão Prev',
    field: 'datesEstimatedGoodsReadinesDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
    sortable: true,
  },
  ],
  // Booking
  1: [{
    label: 'Referência',
    field: 'identification',
  },
  {
    label: 'Etapa',
    field: 'step',
  },
  {
    label: 'Empresa',
    field: 'customer.name',
    sortable: true,
    width: '200px',
  },
  {
    label: 'Exportador',
    field: 'exporter.name',
    width: '200px',
  },
  {
    label: 'Ref Pedido',
    field: 'customerRef',
  },
  {
    label: 'Outras Refs',
    field: 'anotherRef',
  },
  {
    label: 'Status',
    field: 'status',
    width: '200px',
  },
  {
    label: 'Invoice',
    field: 'invoice',
  },
  {
    label: 'Incoterm',
    field: 'incoterm',
  },
  {
    label: 'CNTR',
    field: 'typeOfBoarding',
  },
  {
    label: 'Prontidão Prev',
    field: 'datesEstimatedGoodsReadinesDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'Forwarder',
    field: 'freightForwarder.name',
  },
  {
    label: 'ETD',
    field: 'datesETD',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'ETA',
    field: 'datesETA',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  ],
  // Trânsito
  2: [{
    label: 'Referência',
    field: 'identification',
  },
  {
    label: 'Etapa',
    field: 'step',
  },
  {
    label: 'Empresa',
    field: 'customer.name',
    sortable: true,
    width: '200px',
  },
  {
    label: 'Exportador',
    field: 'exporter.name',
    width: '200px',
  },
  {
    label: 'Ref Pedido',
    field: 'customerRef',
  },
  {
    label: 'Outras Refs',
    field: 'anotherRef',
  },
  {
    label: 'Status',
    field: 'status',
    width: '200px',
  },
  {
    label: 'Invoice',
    field: 'invoice',
  },
  {
    label: 'Incoterm',
    field: 'incoterm',
  },
  {
    label: 'CNTR',
    field: 'typeOfBoarding',
  },
  {
    label: 'Forwarder',
    field: 'freightForwarder.name',
  },
  {
    label: 'Embarque',
    field: 'datesDepartureDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'ETA',
    field: 'datesETA',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'Originais',
    field: 'originalsDocumentsDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'Recinto',
    field: 'customsEnclosure.alias',
  },
  ],
  // 'Desembaraço'
  3: [{
    label: 'Referência',
    field: 'identification',
  },
  {
    label: 'Etapa',
    field: 'step',
  },
  {
    label: 'Empresa',
    field: 'customer.name',
    sortable: true,
    width: '200px',
  },
  {
    label: 'Exportador',
    field: 'exporter.name',
    width: '200px',
  },
  {
    label: 'Ref Pedido',
    field: 'customerRef',
  },
  {
    label: 'Outras Refs',
    field: 'anotherRef',
  },
  {
    label: 'Status',
    field: 'status',
    width: '200px',
  },
  {
    label: 'Invoice',
    field: 'invoice',
  },
  {
    label: 'Incoterm',
    field: 'incoterm',
  },
  {
    label: 'CNTR',
    field: 'typeOfBoarding',
  },
  {
    label: 'Embarque',
    field: 'datesDepartureDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'Chegada',
    field: 'datesArrivalDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'Recinto',
    field: 'customsEnclosure.alias',
  },
  {
    label: 'Nº DI',
    field: 'diNumber',
  },
  {
    label: 'Registro',
    field: 'diRegistryDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  ],
  // Carregamento
  4: [{
    label: 'Referência',
    field: 'identification',
  },
  {
    label: 'Etapa',
    field: 'step',
  },
  {
    label: 'Empresa',
    field: 'customer.name',
    sortable: true,
    width: '200px',
  },
  {
    label: 'Exportador',
    field: 'exporter.name',
    width: '200px',
  },
  {
    label: 'Ref Pedido',
    field: 'customerRef',
  },
  {
    label: 'Outras Refs',
    field: 'anotherRef',
  },
  {
    label: 'Status',
    field: 'status',
    width: '200px',
  },
  {
    label: 'Invoice',
    field: 'invoice',
  },
  {
    label: 'Incoterm',
    field: 'incoterm',
  },
  {
    label: 'CNTR',
    field: 'typeOfBoarding',
  },
  {
    label: 'Transportadora',
    field: 'conveyor.name',
  },
  {
    label: 'Chegada',
    field: 'datesArrivalDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'Desembaraço',
    field: 'diResourcefulnessDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'NF',
    field: 'billingNFsEmissionDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },

  ],
  // Encerramento
  5: [{
    label: 'Referência',
    field: 'identification',
  },
  {
    label: 'Etapa',
    field: 'step',
  },
  {
    label: 'Empresa',
    field: 'customer.name',
    sortable: true,
    width: '200px',
  },
  {
    label: 'Exportador',
    field: 'exporter.name',
    width: '200px',
  },
  {
    label: 'Ref Pedido',
    field: 'customerRef',
  },
  {
    label: 'Outras Refs',
    field: 'anotherRef',
  },
  {
    label: 'Status',
    field: 'status',
    width: '200px',
  },
  {
    label: 'Invoice',
    field: 'invoice',
  },
  {
    label: 'Incoterm',
    field: 'incoterm',
  },
  {
    label: 'CNTR',
    field: 'typeOfBoarding',
  },
  {
    label: 'Embarque',
    field: 'datesDepartureDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'Chegada',
    field: 'datesArrivalDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'DI',
    field: 'diIdentification',
  },
  {
    label: 'Registro',
    field: 'diRegistryDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'NF',
    field: 'billingNFsEmissionDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'Entrega',
    field: 'datesCustomerDeliverDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  {
    label: 'Faturamento',
    field: 'billingBillCustomerDate',
    type: 'date',
    dateOutputFormat: 'dd/MM/yy',
    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
  },
  ],
};

function moneyFormat(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
}

export default columns;
