<template>
  <div v-if="Object.keys(user).length > 0"
       class="flex ai-c ph-2 fgap2 pointer relative user-menu-wrapper"
       @click="menuOpen = !menuOpen">
    <div class="profile-picture flex ai-c jc-c">
      <div :class="['profile-image', user.photo ? '' : 'flex ai-c jc-c']">
        <img :src="'data:image/png;base64,' + user.photo" alt="nome" v-if="user.photo">
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
             fill="none" stroke="white" stroke-width="2" stroke-linecap="round"
             stroke-linejoin="round" class="feather feather-user">
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
      </div>
    </div>

    <div class="user-info">
      <p class="text-primary text-bold"> {{ user.name }}</p>
      <p class="text-light text-sm company-name">{{ user.email }}</p>
    </div>

    <div class="chevron-indicator flex ai-c jc-c">
      <ChevronDownIcon class="text-primary"/>
    </div>

    <transition name="notifications">
      <ul class="user-menu" v-if="menuOpen">
        <li>
          <router-link :to="{ name: 'MyData' }">
            Meus Dados
          </router-link>
        </li>
        <li>
          <a href="#" @click.prevent="logout">Sair</a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { ChevronDownIcon } from '@/components/Icons';

export default {
  name: 'UserMenu',
  components: {
    ChevronDownIcon,
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
  mounted() {
    document.addEventListener('click', (event) => {
      if (!event.target.closest('.user-menu-wrapper')) {
        this.menuOpen = false;
      }
    });
  },
};
</script>

<style scoped>
.user-menu-wrapper {
  position: relative;
  z-index: 999999;
}

.profile-picture {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: var(--round-border-radius);
  background: linear-gradient(45deg, var(--accent-color), var(--primary-color));
}

.profile-image {
  width: calc(3.5rem - 8px);
  height: calc(3.5rem - 8px);
  border-radius: var(--round-border-radius);
  overflow: hidden;
  border: var(--large-border-width) solid var(--body-background);
}

.chevron-indicator {
  background-color: var(--light-bg-color);
  width: 1.75rem;
  height: 1.75rem;
  border-radius: var(--round-border-radius);
}

.user-menu {
  position: absolute;
  z-index: 999;
  top: calc(100% + 1rem);
  right: 0;
  min-width: 280px;
  background-color: var(--white-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--medium-border-radius);
}

.user-menu li:not(:last-of-type) {
  border-bottom: var(--input-border);
}

.user-menu a {
  display: block;
  padding: 1rem;
}

.user-info .company-name {
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
