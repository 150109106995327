import moment from 'moment';

function moneyFormat(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}

/* eslint-disable quote-props */
const ProcessesReportColumnsExcel = {
  'Ref': 'identification',
  'Etapa': 'step.description',
  'Empresa': 'customer.name',
  'Exportador': 'exporter.name',
  'Ref Pedido': 'customerRef',
  'Outras Referencias': 'anotherRef',
  'Status': 'status',
  'Invoice': 'invoice',
  'Incoterm': 'incoterm',
  'CNTR': 'typeOfBoarding',
  'Entrega Cliente (Prevista)': {
    field: 'datesEstimatedCustomerDeliverDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Prodidão Prevista': {
    field: 'datesEstimatedGoodsReadinesDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'ETD': {
    field: 'datesETD',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'ETA': {
    field: 'datesETA',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Embarque': {
    field: 'datesDepartureDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Chegada': {
    field: 'datesArrivalDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Nº DI': 'diNumber',
  'Registro': {
    field: 'diRegistryDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Valor CIF': {
    field: 'cifValue',
    callback: (value) => `${moneyFormat(value)}`,
  },
};

export const CalendarColumnsExcel = {
  'Ref': 'identification',
  'Etapa': 'step.description',
  'Empresa': 'customer.name',
  'Exportador': 'exporter.name',
  'Ref Pedido': 'customerRef',
  'Invoice': 'invoice',
  'Incoterm': 'incoterm',
  'Entrega Cliente (Prevista)': {
    field: 'datesEstimatedCustomerDeliverDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Prodidão Prevista': {
    field: 'datesEstimatedGoodsReadinesDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'ETD': {
    field: 'datesETD',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'ETA': {
    field: 'datesETA',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Valor Numerário Prev': {
    field: 'stepCashRequestEstimatedValue',
    callback: (value) => `${moneyFormat(value)}`,
  },
};

export default ProcessesReportColumnsExcel;
