<template>
  <div class="login-page full-height columns">
    <div class="span-6 full-height bg p-2 hide-mobile">
      <router-link :to="{ name: 'Login' }">
        <img alt="SOWport" src="@/assets/img/logo-completa.png">
      </router-link>
    </div>

    <div class="span-6 flex fd-c jc-c ai-c">
      <router-link :to="{ name: 'Login' }" class="hide-desktop">
        <img src="@/assets/img/logo-completa.png" alt="SOWport">
      </router-link>

      <div class="medium-border-radius pv-4 ph-6 text-center flex fd-c jc-c">
        <h1 class="mb-1 text-xg text-bold">Recuperação de senha</h1>
        <p class="mb-3">Digite o seu email redefinir a senha</p>

        <form class="flex fd-c fgap2">
          <Input
            label="Email"
            type="email"
            name="email"
            placeholder="nome@email.com.br"
            :required="true"
            v-model="userData.email"
            className="input-login"
          />

          <button type="submit" class="btn solid primary full-width extra-big"
                  @click.prevent="() => recovery()">RECUPERAR SENHA
          </button>
        </form>

        <router-link :to="{ name: 'Login' }" class="mt-2">Voltar para login</router-link>
      </div>
    </div>

    <cookies-policy/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Input from '@/components/DataInput/Input.vue';
import CookiesPolicy from '../../components/CookiesPolicy.vue';

/* eslint-disable */
import api from '@/services/api';

export default {
  name: 'Login',
  components: {
    CookiesPolicy,
    Input,
  },
  data() {
    return {
      userData: {
        email: '',
      },
    };
  },
  methods: {
    ...mapActions(['toggleLoading']),
    async recovery() {
      this.toggleLoading(true);

      try {
        const response = await api.post('/credential/recovery/password', {email: this.userData.email}, {
          headers: {Authorization: ''},
        });
        if (response && response.data.code === 'SUCCESS') {
          this.$toast.success(response.data.message, {
            position: 'top-center',
            pauseOnHover: true,
            dismissible: true,
            duration: 10000,
          });
          this.$router.push('/codigo-verificacao');
        }
      } catch (err) {
        this.$toast.error(`Não foi possível executar esta ação no momento: ${err} `);
      }
      this.toggleLoading(false);
    },
  },
};
</script>

<style scoped>
.login-page .bg {
  background: url('/bg-login-new.jpg') center center / cover no-repeat;
}

form {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.login-page.columns {
  background: var(--white-color);
}
</style>
